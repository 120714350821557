import { ACCESS_TOKEN_KEY } from '../constants'
import { API_SERVICE_URL } from '../env'
import { removeAuthKeys, storeKey } from '../functions'
import buildAPIClient from './build-client'

const apiClient = buildAPIClient(API_SERVICE_URL)

const api = {
  async loginUser(values) {
    const token = await apiClient
      .post('/auth/login', values)
      .then((response) => {
        return response.data.token
      })

    await apiClient
      .post('/auth/verify', null, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        return response.data.user
      })

    storeKey(ACCESS_TOKEN_KEY, token)

    return token
  },

  logoutUser() {
    return apiClient.post('/auth/logout').then(function (response) {
      removeAuthKeys()
      return response
    })
  },

  verifyCurrentUser() {
    return apiClient.post('/auth/verify').then(function (response) {
      return response.data.user
    })
  },

  getCurrentUser() {
    return apiClient.get(`/user`).then(function (response) {
      return response.data.user
    })
  },

  updatePassword(params) {
    return apiClient.put('/password', params).then(function (response) {
      return response.data
    })
  },

  resetPassword(email) {
    return apiClient
      .post('/passwords/reset', { email })
      .then(function (response) {
        return response.data
      })
  },
}

export default api
